import React from 'react';
import {HashRouter, Routes,Route} from 'react-router-dom';


import Home from './layout/Home';
import About from './layout/About';
import Portfolio from './layout/Portfolio';
import Careers from './layout/Careers';
import Contact from './layout/Contact';
import Hireus from './layout/Hireus';
import TermsofServices from './layout/TermsofServices';
import PrivacyPolicy from './layout/PrivacyPolicy';

import BrandDesigning from './layout/BrandDesigning';
import WebDevelopment from './layout/WebDevelopment';
import AppDevelopmen from './layout/AppDevelopmen';
import DigitalMarketing from './layout/DigitalMarketing';
import Webhosting from './layout/Webhosting';

function App() {
  return (
    <>
    <HashRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/portfolio' element={<Portfolio />} />
          <Route exact path='/careers' element={<Careers />} />          
          <Route exact path='/contact-us' element={<Contact />} />
          <Route exact path='/hire-us' element={<Hireus />} />

          <Route exact path='/terms-of-services' element={<TermsofServices  />} />
          <Route exact path='/privacy-policy' element={<PrivacyPolicy  />} />

          <Route exact path='/services/brand-designing' element={<BrandDesigning  />} />
          <Route exact path='/services/web-development' element={<WebDevelopment  />} />
          <Route exact path='/services/app-developmen' element={<AppDevelopmen  />} />
          <Route exact path='/services/digital-marketing' element={<DigitalMarketing  />} />

          <Route exact path='/services/webhosting' element={<Webhosting />} />
          
        </Routes>
    </HashRouter>
    </>   
  );
}

export default App;
