import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function Team() {

  
    return(
      <>
     <Grid className="teamWrap" pt={5} pb={5}>
                <Container>
                    <Grid container spacing={3}>
                        <Grid item xs={12} textAlign="center">
                            <Typography variant="h6" p={0}><img src="images/icon.png" /></Typography>
                            <Typography variant="h6" p={0}>GREAT TEAM</Typography>
                            <Typography variant="h4" fontWeight="bold" pb={0}>Our Skilled <Typography variant="body" color="primary">Expert Team</Typography></Typography>
                        </Grid>

                    
                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                {/* <img src="images/team/kaushik.jpg" width="100%" /> */}
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Kaushik Maitra
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Fullstack Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                {/* <img src="images/team/ashok.jpg" width="100%" /> */}
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Ashok Mahato
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Frontend Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                {/* <img src="images/team/mrinmoy.jpg" width="100%" /> */}
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Mrinmoy Koley
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Laravel, Node JS Developer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                        <Grid item md={3} sm={6} xs={6}>
                            <Card>
                                {/* <img src="images/team/manish.jpg" width="100%" /> */}
                                <img src="images/team/cw-user.jpg" width="100%" />
                                <CardContent>
                                    <Typography variant="h5" textAlign="center" color="primary">
                                    Manish Mandal
                                    </Typography>
                                    <Typography variant="subtitle1" color="text.secondary" textAlign="center">Website/App Designer</Typography>
                                </CardContent>                        
                            </Card>
                        </Grid>

                      

                      
                        
                </Grid>
                </Container>
            </Grid>
      
      
      

      



     
      </>
  );
}

export default Team;