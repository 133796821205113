
import React  from "react";

import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';


function AboutContent() {

  
    return(
        <>

{/* Banner Start */}

    
<Grid className="innerHeader" py={5} textAlign="center">
        <Typography variant="h2" fontWeight="bold">About  <Typography variant="body">Us</Typography></Typography>
</Grid>
    

   <section className="midWrapper">



<Grid className="abt_row" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>
                           
                           <Grid item sm={6}>
                           <Card className="abt_img"><img src="images/about_us2.jpg" variant="rounded" /></Card>
                                                         
                           </Grid>
   
                           <Grid item sm={6}>                           
                            <Typography variant="h6" p={0} sx={{display: 'flex'}}>
                                <Typography variant="body" pr={2}><img src="images/icon.png" /></Typography>
                                <Typography variant="body">About CHeap Web Guru Solutions</Typography>
                            </Typography>
                            <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Cheap Web Guru Solutions is one of the best<Typography variant="body" className="yellowTxt" fontWeight="normal"> freelance web solutions in India.</Typography></Typography>
                            <Typography variant="body2" py={2} sx={{textAlign: 'justify'}} >Cheap Web Guru Solutions is a 10-year-old digital services company specializing in web and app development. Founded in 2013, Cheap Web Guru Solutions has grown to become a leader in the industry, providing innovative solutions to businesses and organizations of all sizes.</Typography>
                            <Typography variant="body2" pb={2} sx={{textAlign: 'justify'}} >Cheap Web Guru Solutions provides a wide range of services, including website design, web development, mobile app development, software development, e-commerce solutions, and more. We specialize in creating custom solutions tailored to the specific needs and goals of our clients. With our team of experienced professionals, we strive to provide the highest quality of service and the most reliable solutions.</Typography>
                           </Grid> 
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>



               <Grid className="serviceRow" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Mission</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <img src="images/mission.jpg" className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12} sx={{textAlign: 'justify'}}>
                                        <Typography variant="body2">Our mission is to design high-quality websites and apps that meet the highest standards of aesthetics, functionality, and usability. We strive to create a user-friendly experience that engages our customers, while providing the best possible service and support.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Vision</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <img src="images/vision.jpg"  className="img_fluid_r"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12} sx={{textAlign: 'justify'}}>
                                        <Typography variant="body2">Our vision is to become the go-to web and app development company for businesses of all sizes. We strive to make our services accessible, efficient and cost-effective for our clients, while maintaining the highest standards of quality. We are dedicated to staying ahead of the curve and innovating in the industry to keep our clients ahead of the competition.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               <Grid className="" pt={5} pb={5}>
                   <Container>
                       <Grid container spacing={5}>


                            <Grid item sm={12}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} textAlign="center">
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">Our Team</Typography>
                                    </Grid>
                                    {/* <Grid item xs={12}>
                                        <img src="images/team.jpg" variant="rounded" width="100%"  />
                                    </Grid> */}
                                    <Grid item xs={12} sx={{textAlign: 'justify'}} >
                                        <Typography variant="body2" pb={2}>Our team consists of experienced web developers, app developers, graphic designers, project managers and customer service representatives. Our team is passionate about web and app development, and we strive to stay up-to-date on the latest technologies and trends. Our team is dedicated to providing our clients with the best possible solutions and customer service.</Typography>

                                        <Typography variant="body2" pb={2}>At Cheap Web Guru Solutions, we believe in providing our clients with the best possible user experience. We utilize the latest technologies and techniques to ensure that our clients’ websites and apps are secure, stable, and scalable.</Typography>
                                        <Typography variant="body2" pb={2}>We understand that every business is unique, which is why we work closely with our clients in order to create custom solutions that meet their individual needs. We pride ourselves on our attention to detail and our dedication to customer satisfaction. </Typography>
                                        <Typography variant="body2" pb={2}>At Cheap Web Guru Solutions, we strive to stay ahead of the curve when it comes to technology. We are constantly researching and exploring new technologies and techniques in order to provide our clients with the best possible solutions.</Typography>
                                        <Typography variant="body2">If you’re looking for a reliable and experienced web and app development company, look no further than Cheap Web Guru Solutions. With our commitment to customer satisfaction and our dedication to providing innovative solutions, we are the perfect choice for all of your web and app development needs.</Typography>

                                
                                    </Grid>
                                </Grid>
                            </Grid>

                            {/* <Grid item sm={6}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <Typography variant="h4" fontWeight="bold" pb={0} className="blueTxt">- Vision</Typography>
                                    </Grid>
                                    <Grid item sm={4} xs={12}>
                                        <img src="images/web_development.png" variant="rounded" width="100%"  />
                                    </Grid>
                                    <Grid item sm={8} xs={12}>
                                        <Typography variant="body2">Our vision is to become the go-to web and app development company for businesses of all sizes. We strive to make our services accessible, efficient and cost-effective for our clients, while maintaining the highest standards of quality. We are dedicated to staying ahead of the curve and innovating in the industry to keep our clients ahead of the competition.</Typography>
                                    </Grid>
                                </Grid>
                            </Grid> */}
                           
   
                                                    
                           
                   </Grid>
                   </Container>
               </Grid>


               </section>
               

        </>
    );
}

export default AboutContent;